import GATSBY_COMPILED_MDX from "C:/Users/jging/Documents/hectiq/website/hectiq.ai/src/data/case-study/fr/dam.mdx";
import * as React from "react";
import {graphql, Link} from 'gatsby';
import {useTranslation} from 'react-i18next';
import {Page} from 'components/core/layouts/base';
import {EmptyHero} from 'components/case-study/hero';
import {Paragraph, H1, H2, H3, H4, Figure, Caption, Meta, MetaField, Href, Author, Summary, PreviousLink, Li, Ul, Title, Citation} from 'components/case-study/typo';
import {MDXProvider} from "@mdx-js/react";
import {Footer} from 'components/core/footer';
import {ContactWithCurve} from 'components/core/contact';
import {MoreArticles} from 'components/case-study/content';
import {FiArrowLeft} from 'react-icons/fi';
const shortcodes = {
  Link,
  Figure,
  Caption,
  Citation,
  Summary,
  Title,
  p: Paragraph,
  li: Li,
  ul: Ul,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  MetaField,
  Meta,
  a: Href
};
function CaseStudyPage({data: {mdx}, children, pageContext}) {
  const {t} = useTranslation("case-study");
  return React.createElement(Page, {
    dark: false
  }, React.createElement(EmptyHero), React.createElement(PreviousLink, null, React.createElement(Link, {
    to: "/case-study",
    className: "flex items-center"
  }, React.createElement("span", null, React.createElement(FiArrowLeft, {
    className: "mr-2"
  })), "  ", React.createElement("span", null, t("nav")))), React.createElement(Title, null, mdx.frontmatter.title), React.createElement(Author, null, mdx.frontmatter.author), React.createElement("div", {
    className: "space-y-8"
  }, React.createElement(MDXProvider, {
    components: shortcodes
  }, children), React.createElement(MoreArticles, pageContext)), React.createElement(ContactWithCurve), React.createElement(Footer));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(CaseStudyPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query ($id: String, $language: String!) {
    mdx(id: { eq: $id }) {
      id,
      frontmatter {
        title,
        author
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
